import React from 'react';
import {
	DEPRECATION_BANNER_V1_DISMISS,
	DEPRECATED_GADGETS_MAP,
	ACTIVITY_STREAMS_SURVEY_BANNER_DISMISS,
	ACTIVITY_STREAMS_WEB_RESOURCE,
	PLATFORM_DASHBOARD_BANNER_DISMISS,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { ActivityStreamsSurveyBanner } from '@atlassian/jira-dashboard-common/src/ui/activity-streams-survey-banner/index.tsx';
import { DeprecationBannerV1 } from '@atlassian/jira-dashboard-common/src/ui/deprecation-banner-v1/index.tsx';
import { PlatformDashboardBanner } from '@atlassian/jira-dashboard-common/src/ui/platform-dashboard-banner/index.tsx';
import { useDashboardBannerState } from '@atlassian/jira-dashboard-common/src/utils/use-dashboard-banner-state.tsx';
import { useDashboardHasGadgetType } from '@atlassian/jira-dashboard-common/src/utils/use-dashboard-has-gadget-type.tsx';
import { usePlatformDashboardsAudience } from '@atlassian/jira-dashboard-common/src/utils/use-platform-dashboards-audience.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const DASHBOARD_BANNERS = {
	endeavour_gadget_deprecation_banner_v1: {
		bannerDismissedId: DEPRECATION_BANNER_V1_DISMISS,
		component: DeprecationBannerV1,
		gadgetTypeMap: DEPRECATED_GADGETS_MAP,
	},
	endeavour_activity_streams_survey_banner: {
		bannerDismissedId: ACTIVITY_STREAMS_SURVEY_BANNER_DISMISS,
		component: ActivityStreamsSurveyBanner,
		gadgetTypeMap: ACTIVITY_STREAMS_WEB_RESOURCE,
	},
	endeavour_platform_dashboard_banner: {
		bannerDismissedId: PLATFORM_DASHBOARD_BANNER_DISMISS,
		component: PlatformDashboardBanner,
		gadgetTypeMap: ACTIVITY_STREAMS_WEB_RESOURCE,
	},
};

/**
 * Please add/remove banners in this component. Leave it blank when there
 * are none.
 */

export const EngagementBanners = ({
	gadgets,
	dashboardId,
}: {
	gadgets: GadgetData[];
	dashboardId: string;
}) => {
	const activityStreamsBannerState = useDashboardBannerState(
		DASHBOARD_BANNERS.endeavour_activity_streams_survey_banner.bannerDismissedId,
		dashboardId,
		false,
	);
	const platformDashboardBannerState = useDashboardBannerState(
		DASHBOARD_BANNERS.endeavour_platform_dashboard_banner.bannerDismissedId,
		dashboardId,
		false,
	);
	const gadgetDeprecationBannerV1State = useDashboardBannerState(
		DASHBOARD_BANNERS.endeavour_gadget_deprecation_banner_v1.bannerDismissedId,
		dashboardId,
		true,
	);

	const userIsEAPEligible = usePlatformDashboardsAudience();

	const bannerConfigs: Array<{
		id: keyof typeof DASHBOARD_BANNERS;
		condition: boolean;
		isDismissed: boolean;
		onDismiss: () => void;
	}> = [
		{
			id: 'endeavour_activity_streams_survey_banner',
			condition:
				useDashboardHasGadgetType(
					gadgets,
					DASHBOARD_BANNERS.endeavour_activity_streams_survey_banner.gadgetTypeMap,
				) && fg('endeavour_activity_streams_survey_banner'),
			isDismissed: activityStreamsBannerState.isDashboardBannerDismissed,
			onDismiss: activityStreamsBannerState.onDashboardBannerDismiss,
		},
		{
			id: 'endeavour_platform_dashboard_banner',
			condition:
				!useDashboardHasGadgetType(
					gadgets,
					DASHBOARD_BANNERS.endeavour_platform_dashboard_banner.gadgetTypeMap,
				) &&
				fg('endeavour_platform_dashboard_banner') &&
				userIsEAPEligible,
			isDismissed: platformDashboardBannerState.isDashboardBannerDismissed,
			onDismiss: platformDashboardBannerState.onDashboardBannerDismiss,
		},
		{
			id: 'endeavour_gadget_deprecation_banner_v1',
			condition:
				useDashboardHasGadgetType(
					gadgets,
					DASHBOARD_BANNERS.endeavour_gadget_deprecation_banner_v1.gadgetTypeMap,
				) && fg('endeavour_gadget_deprecation_banner_v1'),
			isDismissed: gadgetDeprecationBannerV1State.isDashboardBannerDismissed,
			onDismiss: gadgetDeprecationBannerV1State.onDashboardBannerDismiss,
		},
	];

	const activeBannerConfig = bannerConfigs.find(
		(config) => config.condition && !config.isDismissed,
	);

	if (!activeBannerConfig) {
		return null; // No banner to show
	}

	const BannerComponent = DASHBOARD_BANNERS[activeBannerConfig.id].component;
	return <BannerComponent onDismiss={activeBannerConfig.onDismiss} />;
};
