import React, { type ReactNode } from 'react';
import Link from '@atlaskit/link';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { PLATFORM_DASHBOARD_BANNER_DISMISS } from '../../constants.tsx';
import messages from './messages.tsx';

export const PlatformDashboardBanner = ({ onDismiss }: { onDismiss: () => void }) => {
	const { formatMessage } = useIntl();
	return (
		<SectionMessage
			appearance="discovery"
			actions={[
				<SectionMessageAction key={PLATFORM_DASHBOARD_BANNER_DISMISS} onClick={onDismiss}>
					{formatMessage(messages.platformDashboardBannerDismiss)}
				</SectionMessageAction>,
			]}
			title={formatMessage(messages.platformDashboardBannerTitle)}
		>
			{formatMessage(messages.platformDashboardBannerMessage, {
				EarlyTesterLink: (chunks: ReactNode[]) => (
					<Link href="https://surveys.atlassian.com/jfe/form/SV_0kAQgoYpYhnVIMu">{chunks}</Link>
				),
				LearnMoreLink: (chunks: ReactNode[]) => (
					<Link href="https://community.atlassian.com/t5/Jira-Cloud-Admins-articles/Join-our-Atlassian-Home-dashboards-alpha/ba-p/2715355">
						{chunks}
					</Link>
				),
			})}
		</SectionMessage>
	);
};
