import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	platformDashboardBannerTitle: {
		defaultMessage: 'Get an early look at our all new dashboards!',
		id: 'dashboard-internal-common.common.gadget.platform-dashboard-banner.platform-dashboard-banner-title.non-final',
		description: 'Title of the platform dashboard EAP banner.',
	},
	platformDashboardBannerMessage: {
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'<EarlyTesterLink>Become an early tester</EarlyTesterLink> for our new Atlassian Home dashboards. Click <LearnMoreLink>here to learn more</LearnMoreLink> about these exciting new dashboards.',
		id: 'dashboard-internal-common.common.gadget.platform-dashboard-banner.platform-dashboard-banner-message.non-final',
		description:
			'An information section message to be displayed to the user about platform dashboard EAP.',
	},
	platformDashboardBannerDismiss: {
		defaultMessage: 'Dismiss',
		id: 'dashboard-internal-common.common.gadget.platform-dashboard-banner.platform-dashboard-banner-dismiss.non-final',
		description: 'Label for the button to dimiss the platform dashboard EAP banner.',
	},
});
