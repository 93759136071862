import { useCallback, useEffect } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

const getUserOrgPermissions = async ({ orgId, userId }: { orgId: string; userId: string }) =>
	performPostRequest<{ permitted: boolean } | null>('/gateway/api/permissions/permitted', {
		body: JSON.stringify({
			resourceId: `ari:cloud:platform::org/${orgId}`,
			principalId: `ari:cloud:identity::user/${userId}`,
			permissionId: 'manage-users',
		}),
	}).then((resp) => !!resp?.permitted);

export type UseIsOrgAdmin = {
	isOrgAdmin: boolean;
	loading: boolean;
	hasErrorOccurred: boolean;
	retry: () => void;
};

export const useIsOrgAdmin = (): UseIsOrgAdmin => {
	const userId = useAccountId();
	const { data: orgId } = useOrgId();

	const checkUserIsOrgAdmin = useCallback(async () => {
		if (!userId || !orgId) {
			return false;
		}
		return getUserOrgPermissions({ orgId, userId });
	}, [orgId, userId]);

	const {
		loading,
		error,
		data,
		fetch: fetchIsOrgAdmin,
	} = useService<boolean>(checkUserIsOrgAdmin, { data: false });

	useEffect(() => {
		fetchIsOrgAdmin();
	}, [fetchIsOrgAdmin]);

	useEffect(() => {
		fireErrorAnalytics({
			meta: {
				id: 'checkUserIsOrgAdmin',
				packageName: 'jiraBusinessGoalPicker',
				teamName: 'jira-yurree',
			},
			sendToPrivacyUnsafeSplunk: true,
			error,
		});
	}, [error]);

	return {
		isOrgAdmin: !!data,
		loading,
		hasErrorOccurred: !!error,
		retry: fetchIsOrgAdmin,
	};
};
