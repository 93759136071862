import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import { Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { withConnectHost } from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import { getIsDropdownDefaultOpen } from '../../../../utils/is-dropdown-default-open.tsx';

type ConnectHeaderWebItem = {
	id: string;
	styleClass: string;
	url: string;
	title: string;
};
export type ConnectHeaderProp = {
	items?: ConnectHeaderWebItem[];
};

export type ConnectHeaderLeftOverProp = {
	items: ConnectHeaderWebItem[];
};

export const ConnectHeaderWebItems = ({ item }: { item: ConnectHeaderWebItem }) => {
	const webItemRef = useRef<HTMLElement | null>(null);
	const onWebItemClick = () => {
		withConnectHost((connectHost) => {
			if (webItemRef.current) {
				connectHost.triggerWebItem(webItemRef.current, item.styleClass, item.url);
			}
		});
	};
	return (
		<Button id={item.id} appearance="subtle" onClick={onWebItemClick} ref={webItemRef}>
			{item.title}
		</Button>
	);
};

const DropdownPressableItem = ({ item }: { item: ConnectHeaderWebItem }) => {
	const webItemRef = useRef<HTMLElement | null>(null);
	const onWebItemClick = () => {
		withConnectHost((connectHost) => {
			if (webItemRef.current) {
				connectHost.triggerWebItem(webItemRef.current, item.styleClass, item.url);
			}
		});
	};
	return (
		<Pressable
			id={item.id}
			onClick={onWebItemClick}
			ref={(ref) => {
				if (ref) {
					webItemRef.current = ref;
				}
			}}
			backgroundColor="color.background.neutral.subtle"
		>
			{item.title}
		</Pressable>
	);
};

export const ConnectHeaderLeftOver = ({ items }: ConnectHeaderLeftOverProp) => {
	if (items.length === 0) {
		return null;
	}

	return (
		<DropdownMenu
			isLoading={false}
			trigger={({ triggerRef, ...triggerProps }: { triggerRef: React.Ref<HTMLElement> }) => (
				<Button appearance="subtle" spacing="default" {...triggerProps} ref={triggerRef}>
					+{items.length}
				</Button>
			)}
			shouldFlip
			placement="bottom-end"
			testId="dashboard-internal-common.ui.dashboard-content.header.connect-header.dropdown-menu"
			defaultOpen={getIsDropdownDefaultOpen('connect-header')}
		>
			{items.map((item) => (
				<DropdownItem key={item.id}>
					<DropdownPressableItem item={item} />
				</DropdownItem>
			))}
		</DropdownMenu>
	);
};

export const ConnectHeader = ({ items }: ConnectHeaderProp) => {
	if (!items) {
		return null;
	}

	return (
		<ConnectHeaderWrapper>
			{items.slice(0, 4).map((item: ConnectHeaderWebItem) => (
				<ConnectHeaderWebItems item={item} key={item.id} />
			))}
			<ConnectHeaderLeftOver items={items.slice(4)} />
		</ConnectHeaderWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ConnectHeaderWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		marginTop: 0,
		marginRight: token('space.050', '4px'),
		marginBottom: 0,
		marginLeft: token('space.050', '4px'),
	},
});
